<template>
  <div id="main-container" :key="componentKey">

    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">
        <div v-if="urdudata == false" class="col-lg-12">
          <div class="p-3">
       
            <div class="w-100">
              <div class="custom-column-banner-heading  ">

                <div class="custom-heading-without-background border-bottom mb-4">

                  <h2 class="text-capitalize">{{ info.title }}</h2>
                </div>
<!--                <div class="custom-widget mb-4 border-bottom">
                        <span class="mr-2">
                          <b-icon icon="clock-history"></b-icon>
                        </span>
                  <span class="custom-service-text text-muted">
                          Updated On 12 Dec 2021
                        </span>
                </div>-->
                <div class="pt-2"></div>
<!--                <div v-if="info.image != '' && info.image != null" class="w-100">
                  <img :src="url+info.image" class="w-100"/>

                </div>
                <div v-else class="w-100">

                  <img src="../../assets/images/whychooseus.jpg" class="w-100">
                </div>-->

              </div>

            </div>
            <div class="pt-5"></div>
            <div class="text-lg-justify" v-html="info.longDescription">
            </div>

          </div>

        </div>
        <div v-else class="col-lg-12">
          <div class="p-3">
       
            <div class="w-100">
              <div class="custom-column-banner-heading  ">

                <div class="custom-heading-without-background border-bottom mb-4">

                  <h2 style="float: right;" class="text-capitalize">{{ info.urduTitle }}</h2>
                </div>
<!--                <div class="custom-widget mb-4 border-bottom">
                        <span class="mr-2">
                          <b-icon icon="clock-history"></b-icon>
                        </span>
                  <span class="custom-service-text text-muted">
                          Updated On 12 Dec 2021
                        </span>
                </div>-->
                <div class="pt-2"></div>
<!--                <div v-if="info.image != '' && info.image != null" class="w-100">
                  <img :src="url+info.image" class="w-100"/>

                </div>
                <div v-else class="w-100">

                  <img src="../../assets/images/whychooseus.jpg" class="w-100">
                </div>-->

              </div>

            </div>
            <div class="pt-5"></div>
            <div style="float: right;" class="text-lg-justify" v-html="info.urduLongDescription">
            </div>

          </div>

        </div>
      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>
      <!--  Navigate To Packages    -->
      <div class="text-center  mb-5">
        <!--      <div class="custom-widget mt-2 ">
                <h2 class=" ">Our Packages</h2>
              </div>-->
        <div class="custom-row justify-content-center">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="trophy"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>

      </div>
      <div>
        <!--      params: { id: 'ourPackages_id' }
        @click="scrollTo($event.target.dataset.id)"
                             data-id="ourPackages_id"
        -->

        <div class="text-center clear-bottom">
          <div class="row no-gutters">
            <div class="col-lg-4 mt-1 mb-1"></div>
            <div class="col-lg-2 mt-1 mb-1">
              <router-link :to="{ name: 'Home', hash: '#ourPackages_id'
                            }"

                           class="btn link-theme text-decoration-none">Explore Packages</router-link>
            </div>
            <div class="col-lg-2 mt-1 mb-1">
              <router-link :to="{ name: 'ContactUs' }"

                           class="btn link-theme text-decoration-none">Contact Us</router-link>
            </div>
            <div class="col-lg-4 mt-1 mb-1"></div>
          </div>

        </div>
      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>

    </div>

  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'PackageDetail',
  components: {

  },
  data () {
    return {

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      urdudata:false,
      list: [],
      PageOfItems: [],
      info: [],
      option: 0,

      // Video Source
      videoUrl: '',
      // posts

      postList: [],

      loading: true,
      errored: false,

      componentKey: 0
    }
  },
  watch: {
    '$i18n.locale': {
      handler(newValue, oldValue) {
        // This function will be called whenever the $i18n.locale property changes
        // You can perform actions or call a function here when the locale changes
        this.onLocaleChange(newValue, oldValue);
      },
      deep: true, // If $i18n.locale is an object, watch for changes within it
    },
  },
  // carousel_latest_jobs
  methods: {
    onLocaleChange(newValue, oldValue) {
      // This function is called when $i18n.locale changes
      // Implement your logic here to handle the locale change
      console.log('Locale changed!');
      console.log('New locale:', newValue);
      console.log('Old locale:', oldValue);

      // Call your desired function here
      this.yourFunctionToHandleLocaleChange(newValue);
    },
    yourFunctionToHandleLocaleChange(newLocale) {
      // Implement your logic here to handle the locale change
    if(newLocale == 'ur'){
      debugger
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
     
    },
    getService () {
      console.log('id : ', this.$route.params.id)
      this.info = []
      axios.get('/api/Shehri/VenturePackagesDetail?id=' + this.$route.params.id)
        .then(res => {
          this.info = res.data.data

          console.log('service Detail : ', this.info)
        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    // Rerender Component
    rerender () {
      this.componentKey += 1
    }
  },

  mounted () {
    if(this.$i18n.locale == 'ur'){
      this.urdudata = true;
    }else{
      this.urdudata = false;
    }
    this.getService()
  }
}
</script>
